<!-- eslint-disable import/extensions -->
<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/no-unresolved -->
<template>
  <div>
    <iframe
      id="referAFriendForm"
      v-resize.quiet="{log:true}"
      width="100%"
      :src="URLmade"
      frameborder="0"
      :height="iframeHeight"
      scrolling="no"
    ></iframe>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
    mdiFilePdf,
    mdiArrowRight,
    mdiMessageTextOutline,
    mdiPhone,
    mdiVideoPlus,
    mdiAccountCircle,
    mdiBasketPlus,
    mdiPost,
} from '@mdi/js'
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import Vue from 'vue'
import { mapState } from 'vuex'
import { useVisitStore } from '@/stores/Visit'

Vue.directive('resize', {
    bind(el, { value = {} }) {
        el.addEventListener('load', () => iFrameResize(value, el))
    },
})
export default {
    name: 'ReferAFriend',
    directives: {
        'iframe-resize': {
            bind(el, binding) {
                iFrameResize(binding.value, el)
            },
        },
    },
    setup() {
        const visitStore = useVisitStore()

        return {
            visitStore,
            icons: {
                mdiFilePdf,
                mdiArrowRight,
                mdiMessageTextOutline,
                mdiPhone,
                mdiVideoPlus,
                mdiAccountCircle,
                mdiBasketPlus,
                mdiPost,
            },
        }
    },
    data() {
        return {
            URLmade: '',
            prePopulate: {},
            iframeHeight: '',
        }
    },
    computed: {
        ...mapState(['User']),
    },
    mounted() {
        window.addEventListener('message', this.receiveMessage)
        this.getURL()
    },
    beforeDestroy() {
        window.removeEventListener('message', this.receiveMessage)
    },
    methods: {
        scrollToForm() {
            document.getElementById('referAFriendForm').scrollIntoView({
                behavior: 'smooth',
            })
        },
        receiveMessage(event) {
            this.eventData = event.data
            if (typeof this.eventData === 'object') {
                // console.log('🚀 ~ file: Enrollment.vue ~ line 92 ~ receiveMessage ~ this.eventData', this.eventData)
            } else {
                const myInt = parseInt(this.eventData.replace(/^[^0-9]+/, ''), 10)
                this.iframeHeight = myInt
            }
        },
        async getURL() {
            this.URLmade = `https://form.jotform.com/221348300696253?patientName=${this.visitStore.patientData.name}&email=${this.visitStore.patientData.email}&nojump`
        },
    },
}
</script>

<style lang="scss" scoped>
.home-bg {
  background-image: url('~@/assets/images/banners/referafriend.png') !important;
  background-position: 40% 40%;
  background-size: cover;
}
</style>
